.divTopoTema.tema-BARMENUS {
    min-height: 100vh;
}

.divTopoTema.tema-BARMENUS .container {
    max-width: 700px;
    margin: auto;
}

.paginaCartao .tema-BARMENUS .conteudo {
    position: relative;
    padding-top: 15px;
    z-index: 1;
}

.paginaCartao .tema-BARMENUS .botoes .fa-youtube {
    margin-top: 4px;
    font-size: 1.8em;
}

.paginaCartao .tema-BARMENUS .btn-link {
    border: 2px solid transparent;
    display: block;
    padding: 15px 20px;
    margin-bottom: 15px;
    font-size: 1rem;
    margin: 0 auto 15px auto;
    text-decoration: none;
    transition: 0.25s;
    border-radius: 10px;
}

.paginaCartao .tema-BARMENUS .btn-link {
    text-align: center;
}

.paginaCartao .tema-BARMENUS .btn-link .link-titulo {
    font-weight: 600;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
}

.paginaCartao .tema-BARMENUS .btn-link .link-titulo:hover {
    font-weight: 600;
}

.fal {
    font-weight: normal;
}

.paginaCartao .tema-BARMENUS .btn-link .link-icon {
    font-size: 20px;
    margin: 0 10px 0 0;
}

.paginaCartao .tema-BARMENUS .btn-link:hover {
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
    transition: 0.25s;
}

.paginaCartao .tema-BARMENUS .nome-perfil {
    font-weight: 700;
}

.paginaCartao .tema-BARMENUS .rodape {
    padding: 10%;
}

.paginaCartao .tema-BARMENUS .appPix .input-esquerdo img {
    filter: brightness(0) invert(1);
}

.paginaCartao .tema-BARMENUS .appPix {
    margin-bottom: 1rem;
}

.paginaCartao .tema-BARMENUS .appPix .input-esquerdo {
    border-radius: 1rem 0rem 0rem 1rem;
    border-color: transparent;
}

.paginaCartao .tema-BARMENUS .appPix .input-direito {
    border-radius: 0rem 1rem 1rem 0rem;
    border-color: transparent;
    cursor: pointer;
}

.paginaCartao .tema-BARMENUS .appPix .input-group .form-control{
    border-color: transparent;
    border: 1px;
}

/* Tela de Mobile */
@media (max-width:767px) {

    .remove-mobile,
    [remove-mobile] {
        display: none !important;
    }
}

/* Tela de Mobile Teclado Ativo */
@media (max-width:767px) and (max-height:450px) {

    .remove-mobile-teclado,
    [remove-mobile-teclado] {
        display: none !important;
    }
}

/* Tela de PC */
@media (min-width:768px) {

    .remove-pc,
    [remove-pc] {
        display: none !important;
    }
}