.divTopoTema.tema-QUADRADOS {
    min-height: 100vh;
}

.divTopoTema.tema-QUADRADOS .container {
    max-width: 700px;
    margin: auto;
}

.paginaCartao .tema-QUADRADOS .conteudo{
    position: relative;
    padding-top: 15px;
    z-index: 1;
}

.paginaCartao .tema-QUADRADOS .botoes .fa-youtube{
    margin-top: 4px;
    font-size: 1.8em;
}

.paginaCartao .tema-QUADRADOS .links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
}

.paginaCartao .tema-QUADRADOS .btn-link{
    width: 100px!important;
    height: 100px!important;
    padding: 10px;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    border: 2px solid transparent;
    padding: 15px 20px;
    font-size: 1rem;
    text-decoration: none;
    transition: 0.25s;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.paginaCartao .tema-QUADRADOS .btn-link {
    text-align: center;
}

.paginaCartao .tema-QUADRADOS .btn-link .link-titulo {
    font-weight: 600;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
}

.paginaCartao .tema-QUADRADOS .btn-link .link-titulo:hover {
    font-weight: 600;
}

.fal {
    font-weight: normal;
}

.paginaCartao .tema-QUADRADOS .btn-link .link-icon {
    font-size: 2em;
    margin: 0 10px 0 0;
}
.paginaCartao .tema-QUADRADOS .btn-link:hover {
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
    transition: 0.25s;
}

.paginaCartao .tema-QUADRADOS .nome-perfil{
    font-weight: 700;
}

.paginaCartao .tema-QUADRADOS .rodape{
    padding: 10%;
}

.paginaCartao .tema-QUADRADOS .appPix .input-esquerdo img {
    filter: brightness(0) invert(1);
}

.paginaCartao .tema-QUADRADOS .appPix {
    margin-bottom: 1rem;
}

.paginaCartao .tema-QUADRADOS .appPix .input-esquerdo {
    border-radius: 1rem 0rem 0rem 1rem;
    border-color: transparent;
}

.paginaCartao .tema-QUADRADOS .appPix .input-direito {
    border-radius: 0rem 1rem 1rem 0rem;
    border-color: transparent;
    cursor: pointer;
}

.paginaCartao .tema-QUADRADOS .appPix .input-group .form-control{
    border-color: transparent;
    border: 1px;
}
/* Tela de Mobile */
@media (max-width:767px) {
    .remove-mobile, [remove-mobile] {
        display: none!important;
    }
}

/* Tela de Mobile Teclado Ativo */
@media (max-width:767px) and (max-height:450px) {
    .remove-mobile-teclado, [remove-mobile-teclado] {
    display: none!important;
    }
}

/* Tela de PC */
@media (min-width:768px) {
    .remove-pc, [remove-pc] {
        display: none!important;
    }
}