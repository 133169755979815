.tema-QUADRADOS .btn-azul{
    color: #fff;
    background-color: #0192ec;
}

.tema-QUADRADOS .btn-azul:hover{
    color: #0192ec;
    border-color: #0192ec;
}

.tema-QUADRADOS .btn-padrao{
    color: #fff;
    background-color:  #9419E6;
}

.tema-QUADRADOS .btn-padrao:hover{
    color:  #9419E6;
    border-color: #9419E6;
}

.tema-QUADRADOS .btn-amarelo{
    color: #fff;
    background-color: #ffad1f;
}

.tema-QUADRADOS .btn-amarelo:hover{
    color: #ffad1f;
    border-color: #ffad1f;
}

.tema-QUADRADOS .btn-verde{
    color: #fff;
    background-color: #00b953;
}

.tema-QUADRADOS .btn-verde:hover{
    color: #00b953;
    border-color: #00b953;
}

.tema-QUADRADOS .btn-vermelho{
    color: #fff;
    background-color: #f12d2d;
}

.tema-QUADRADOS .btn-vermelho:hover{
    color: #f12d2d;
    border-color: #f12d2d;
}

.tema-QUADRADOS .btn-rosa{
    color: #fff;
    background-color: #f12dd7;
}

.tema-QUADRADOS .btn-rosa:hover{
    color: #f12dd7;
    border-color: #f12dd7;
}

.tema-QUADRADOS .btn-preto-branco{
    color: #fff;
    background-color: #000;
}

.tema-QUADRADOS .btn-preto-branco:hover{
    color: #000;
    border-color: #000;
}

.tema-QUADRADOS .btn-preto{
    color: #fff;
    background-color: #000;
}

.tema-QUADRADOS .btn-preto:hover{
    color: #000;
    border-color: #000;
}

.tema-QUADRADOS .btn-branco{
    color: #000;
    background-color: #eee;
}

.tema-QUADRADOS .btn-branco:hover{
    color: #000;
    border-color: #eee;
}

.tema-QUADRADOS.background-padrao{
    background-color: #ecd2fa;
}

.tema-QUADRADOS.background-verde{
    background-color: #9fe69f;

}

.tema-QUADRADOS.background-vermelho{
    background-color: #f08080;
}

.tema-QUADRADOS.background-amarelo{
    background-color: #ecec8a;
}

.tema-QUADRADOS.background-azul{
    background-color: #6eabd1;
}

.tema-QUADRADOS.background-rosa{
    background-color: #ec8ad3;
}

.tema-QUADRADOS.background-preto{
    background-color: #4e4e4e;
    color: #fff;
}

.tema-QUADRADOS.background-branco{
    background-color: #fff;
}

.tema-QUADRADOS.background-preto-branco{
    background-color: #fff;
}

.tema-PADRAO .pix-cor-padrao .input-group-text,
.tema-PADRAO .pix-cor-padrao .form-control {
    color: #fff;
    background-color: #9419E6;
}

.tema-QUADRADOS .pix-cor-azul .input-group-text,
.tema-QUADRADOS .pix-cor-azul .form-control {
    color: #fff;
    background-color: #0192ec;
}

.tema-QUADRADOS .pix-cor-verde .input-group-text,
.tema-QUADRADOS .pix-cor-verde .form-control {
    color: #fff;
    background-color: #00b953;
}

.tema-QUADRADOS .pix-cor-amarelo .input-group-text,
.tema-QUADRADOS .pix-cor-amarelo .form-control {
    color: #fff;
    background-color: #ffad1f;
}

.tema-QUADRADOS .pix-cor-vermelho .input-group-text,
.tema-QUADRADOS .pix-cor-vermelho .form-control {
    color: #fff;
    background-color: #f12d2d;
}

.tema-QUADRADOS .pix-cor-rosa .input-group-text,
.tema-QUADRADOS .pix-cor-rosa .form-control {
    color: #fff;
    background-color: #f12dd7;
}

.tema-QUADRADOS .pix-cor-preto-branco .input-group-text,
.tema-QUADRADOS .pix-cor-preto-branco .form-control {
    color: #fff;
    background-color: #000;
}

.tema-QUADRADOS .pix-cor-preto .input-group-text,
.tema-QUADRADOS .pix-cor-preto .form-control {
    color: #fff;
    background-color: #000;
}

.tema-QUADRADOS .pix-cor-branco .input-group-text,
.tema-QUADRADOS .pix-cor-branco .form-control {
    color: #000;
    background-color: #eee;
}
.paginaCartao .tema-QUADRADOS .pix-cor-branco .input-esquerdo img {
    filter: brightness(0) invert(0);
}