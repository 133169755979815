a {
    cursor: pointer;
}

.swal2-container{
    overflow-y: auto!important;
}

.card.selecionado {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
}

.modal-galeria-icones .icones .favoritos,
.modal-galeria-icones .icones .outros {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.modal-galeria-icones .icones .btn-icone {
    color: #8a629a;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 15px;
    margin: 5px;
    cursor: pointer;
    transition: 0.25s;
}

.modal-galeria-icones .icones .btn-icone:hover {
    background-color: #d6d4d4;
    transition: 0.25s;
}

.modal-galeria-icones .icones .outros {
    display: none;
}

.modal-galeria-icones .modal-title p {
    font-size: 15px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 0;
}

.btn-redondo-cor {
    border-radius: 100%;
    padding: 20px;
    margin-right: 5px;
    border: solid 1px rgb(128, 127, 127);
    margin-top: 10px;
}

.paginaUser .btn-roxo[corBanco='roxo'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #8303d8;
}

.paginaUser .btn-vermelho[corBanco='vermelho'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #c82333;
}

.paginaUser .btn-verde[corBanco='verde'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #218838;
}

.paginaUser .btn-amarelo[corBanco='amarelo'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #e0a800;
}

.paginaUser .btn-rosa {
    background-color: #f12dd7;
}

.paginaUser .btn-rosa[corBanco='rosa'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #f12dd7;
}

.paginaUser .btn-preto-branco {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(255, 255, 255, 1) 50%);
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(255, 255, 255, 1) 50%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(255, 255, 255, 1) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ffffff", GradientType=1);
}

.paginaUser .btn-preto-branco[corBanco='preto-branco'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #000;
}


.paginaUser .btn-preto {
    background-color: #000000;
}

.paginaUser .btn-preto[corBanco='preto'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #000000;
}


.paginaUser .btn-branco {
    background-color: #ffffff;
}

.paginaUser .btn-branco[corBanco='branco'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #ffffff;
}

.paginaUser .btn-azul[corBanco='azul'] {
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
    background-color: #0069d9;
}

.btn-link {
    color: #8A629A;
}

.btn-link:hover {
    color: #633a74;
}

.btn-painel-roxo {
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: -webkit-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cca5f3", endColorstr="#8a629a", GradientType=1);
    color: #fff !important;
    border: none;
    font-weight: 600;
}

.btn-painel-roxo:hover {
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(170deg, rgba(138, 98, 154, 1) 0%, rgba(204, 165, 243, 1) 100%);
    background: -webkit-linear-gradient(170deg, rgba(138, 98, 154, 1) 0%, rgba(204, 165, 243, 1) 100%);
    background: linear-gradient(170deg, rgba(138, 98, 154, 1) 0%, rgba(204, 165, 243, 1) 100%);
    color: #fff !important;
    border: none;
    font-weight: 600;
}

.galeria-temas .card-body {
    background-color: #fff;
}


.galeria-temas .card img {
    max-height: 309px;
    object-fit: cover;
    object-position: top;
}

.galeria-temas [corBanco="padrao"].card img {
    filter: invert(38%) sepia(100%) saturate(7447%) hue-rotate(274deg) brightness(92%) contrast(101%);
}

.galeria-temas [corBanco="padrao"].card {
    background-color: #ecd2fa;
}

.galeria-temas [corBanco="verde"].card img {
    filter: invert(50%) sepia(100%) saturate(372%) hue-rotate(82deg) brightness(86%) contrast(87%);
}

.galeria-temas [corBanco="verde"].card {
    background-color: #9fe69f;
}

.galeria-temas [corBanco="azul"].card img {
    filter: invert(72%) sepia(80%) saturate(1824%) hue-rotate(197deg) brightness(103%) contrast(140%);
}

.galeria-temas [corBanco="azul"].card {
    background-color: #6eabd1;
}

.galeria-temas [corBanco="vermelho"].card img {
    filter: invert(50%) sepia(50%) saturate(5823%) hue-rotate(331deg) brightness(80%) contrast(107%);
}

.galeria-temas [corBanco="vermelho"].card {
    background-color: #f08080;
}

.galeria-temas [corBanco="amarelo"].card img {
    filter: invert(50%) sepia(60%) saturate(661%) hue-rotate(363deg) brightness(100%) contrast(103%);
}

.galeria-temas [corBanco="amarelo"].card {
    background-color: #ecec8a;
}

.galeria-temas [corBanco="rosa"].card img {
    filter: invert(50%) sepia(57%) saturate(3159%) hue-rotate(282deg) brightness(90%) contrast(112%);
}

.galeria-temas [corBanco="rosa"].card {
    background-color: #ec8ad3;
}

.galeria-temas [corBanco="preto"].card img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(169deg) brightness(104%) contrast(101%);
}

.galeria-temas [corBanco="preto"].card {
    background-color: #4e4e4e;
}

.galeria-temas [corBanco="branco"].card img {
    filter: invert(0%) sepia(100%) saturate(22%) hue-rotate(68deg) brightness(70%) contrast(98%);
}

.galeria-temas [corBanco="preto-branco"].card img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(169deg) brightness(104%) contrast(101%);
}

.paginaUser {
    background-color: #fff;
    color: #000;
}

.ocultar{
    display: none;
}

.paginaCartao {
    min-width: 100%;
    min-height: 100%;
}

.img-print-tema{
    height: 40vh;
    object-fit: cover;
    object-position: top;
}

.p-senha {
    font-size: 12px;
    color: rgb(88, 88, 88);
}

.img-print-tema {
    border: 0.2rem #000 solid;
    border-radius: 1.5rem;
}

.limite-height-5rem {
    max-height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.limite-height-10rem {
    max-height: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.limite-1linha-ch-100p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.limite-1linha-ch-15 {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.limite-1linha-ch-20 {
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.limite-1linha-ch-30 {
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-loja .card-title {
    margin: 0px;
}

/* .navbar {
    color: #fff;
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: -webkit-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    box-shadow: none;
    height: 66px;
    padding: 0 1rem 0 1rem;
} */

.nav-loja {
    background: #F8F9FA;
    color: #000;
    border: none;
    justify-content: center;
}

.nav-loja .nav-link {
    color: #000;
    border: none;
    border-bottom: solid 2.5px rgba(0, 0, 0, 0) !important;
}

.nav-loja .nav-link.active {
    border-radius: 0px !important;
    border-bottom: solid 2.5px #8a629a !important;
}

.navbar-collapse.show .nav-link,
.navbar-collapse.collapsing .nav-link {
    font-size: 1.1em !important;
    color: #000;
}

.navbar-collapse.show {
    height: 100vh;
    padding: 20px 0px 0px 10px;
    transition: 0.3s;
}

.navbar-collapse.collapsing {
    padding: 20px 0px 0px 10px;
    transition: 0.3s;
}

.navbar-icon-voltar,
.navbar-toggler {
    border: none;
    background: transparent;
    font-size: 1.5em;
    color: #000 !important;
    outline: none !important;
    box-shadow: none !important;
    width: 50px;
    transition: 0.5s;
}

.navbar-icon-voltar:hover,
.navbar-icon-voltar:active,
.navbar-icon-voltar:focus,
.navbar-toggler:hover,
.navbar-toggler:active,
.navbar-toggler:focus {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.15);
    transition: 0.5s;
}

.navMobileLink {
    display: inline-block;
    color: #000;
    text-align: center;
    text-decoration: none;
    margin: 0px 10px 0px 10px;
    padding: 3px;
    transition: 0.5s;
}

.navMobileLink.active,
.nav-link.active {
    color: #8a629a !important;
    background: transparent !important;
}

.navMobileLink:hover,
.navMobileLink:focus,
.nav-loja .nav-link:hover,
.nav-loja .nav-link:focus {
    border-radius: 10px;
    color: #000;
    background: rgba(0, 0, 0, 0.15);
    transition: 0.5s;
}

.navMobileLink i {
    font-size: 1.5em;
    text-align: center;
}

.btn-outline-success {
    border-color: #fff;
    color: #fff;
}

.btn-outline-success:hover {
    border-color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    border-color: #9419E6;
    background-color: #9419E6;
}

.icon-eye a {
    color: #4ec554;
    transition: 0.25s;
}

.icon-eye a:hover {
    color: #227526;
    transition: 0.25s;
}

.dados-form {
    margin-right: 10%;
}

.card-link {
    margin-bottom: 15px;
}

.card-link-move {
    width: 100%;
}

.cards-link-move .card-link-move .card-link .card-body .handle {
    width: 100%;
}

.form-control {
    border-color: transparent;
    border-bottom: solid 2px #ccc;
}

.form-control[disabled] {
    background: transparent;
    cursor: no-drop;
    box-shadow: none;
    border-bottom: solid 1px #f1a8a8;
    transition: 1s;
}

.form-control:focus {
    /* box-shadow: 0 0 0 0.1rem rgba(193, 100, 255, 0.5)!important; */
    border-radius: none;
    box-shadow: none;
    border-color: transparent;
    border-bottom: solid 2px #d9a7e9;
    transition: 1s;
}

.icon-trash a {
    color: #ec7f7f;
    transition: 0.5s;
    cursor: pointer;
}

.icon-trash a:hover {
    color: red;
    transition: 0.5s;
}

.form-card {
    border: none;
    border-bottom: 1px #b2b3b4 solid;
    border-radius: 0;
    margin: 0 0 2px 0;
}


.link-site {
    color: #333;
}

.grid-tema {
    cursor: pointer;
    display: contents;
    margin: 0 15px 0 0;
}

.tema-padrao,
.tema-barmenus {
    width: 30%;
}

.btn-editar-cartao{
    color: #fff;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
}

.btn-roxo {
    background-color: #9419E6;
}

.btn-roxo:not(:disabled):not(.disabled):active,
.btn-roxo:focus,
.btn-roxo:hover {
    background-color: #5e019c;
    border-color: #5e019c00;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:focus,
.btn-light:hover {
    background-color: #5e019c;
    border-color: #5e019c00;
}

.check-logado {
    margin-top: 2rem;
}

.form-text-check {
    margin-left: 0.6rem;
}

.form-control {
    width: 100%;
    border-radius: none;
    transition: 1s;
}

.btn-light.focus,
.btn-light:focus {
    background-color: #8303d8;
    box-shadow: 0 0 0 0.2rem rgba(150, 48, 218, 0.5);
}

.btn-warning,
.btn-light {
    color: #333
}

.content {
    margin-left: 40px;
}

.fa-arrows-v {
    color: #adb5bd;
    padding-top: 23px;
    margin-left: -2px;
}

.fa-arrows-v:hover {
    cursor: -webkit-grabbing;
}

.col-cards {
    padding-left: 5px;
}

h1,
h2,
h3,
h4 {
    font-family: 'arial';
}

.date {
    color: #ffffff;
}

.logo-app {
    border-radius: 20px;
}

.preview-pagina {
    border: 15px #000 solid;
    border-radius: 3rem;
    display: flex;
    height: 692px;
    width: 352px;
    text-align: center;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
}

.preview-pagina::-webkit-scrollbar {
    width: 0px;
}

.banner-perfil {
    margin: 0 auto 15px auto;
}

.banner-perfil img {
    object-fit: cover;
    bottom: 0;
    width: 100%;
    max-height: 150px;
    border-radius: 10px;
}

.imagem-perfil {
    background-color: transparent;
    margin: auto;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    display: flex;
}

.mt-negativo-70{
    margin-top: -70px;
}

.imagem-perfil img {
    object-fit: cover;
    bottom: 0;
    width: 100%;
}

.btn-imagem {
    text-align: center !important;
}

/* input[type="file"] {
    display: none;
} */

.custom-file-upload {
    border-radius: 6px;
    display: inline-block;
    padding: 6px 12px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    justify-items: center !important;
    margin: auto;
    border: none;
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: -webkit-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
}

.custom-file-upload:hover {
    border-radius: 6px;
    display: inline-block;
    padding: 6px 12px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    justify-items: center !important;
    margin: auto;
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(170deg, rgba(138, 98, 154, 1) 0%, rgba(204, 165, 243, 1) 100%);
    background: -webkit-linear-gradient(170deg, rgba(138, 98, 154, 1) 0%, rgba(204, 165, 243, 1) 100%);
    background: linear-gradient(170deg, rgba(138, 98, 154, 1) 0%, rgba(204, 165, 243, 1) 100%);
}

.btn-link-share {
    border-radius: 6px;
    display: inline-block;
    padding: 5px 11px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
    justify-items: center !important;
    margin: auto;
    background-color: #ebebeb;
    border: 1px #979797 solid;
    margin-left: 5px;
}

.btn-link-share:hover {
    color: #9419E6;
}

.btn-link-copy {
    border-radius: 6px;
    display: inline-block;
    padding: 5px 11px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
    justify-items: center !important;
    margin: auto;
    background-color: #ebebeb;
    border: 1px #979797 solid;
}

.btn-link-copy:hover {
    color: #9419E6;
}

.a-link {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 7px;
}

.a-link:hover {
    text-decoration: underline;
    color: #9419E6;
}

.coluna-form {
    padding-right: 0rem;
}

.save {
    float: right;
}

.post-form textarea,
.post-form input {
    width: 100%;
}

.top-menu,
.top-menu:hover,
.top-menu:visited {
    color: #ffffff;
    float: right;
    font-size: 26pt;
    margin-right: 20px;
}

.card-whatsapp {
    border: none;
}

.post {
    margin-bottom: 70px;
}

.post h2 a,
.post h2 a:visited {
    color: #000000;
}

.cards-margin {
    margin-bottom: 0.25rem;
    border-color: rgb(216, 215, 215);
}

.icone .icone-perfil {
    color: #495057;
    /* margin-top: 15px; */
    display: inline-block;
    /* z-index: 1; */
    padding: 9px 12px;
    /* margin-left: -27px; */
    cursor: pointer;
}

.icone-perfil:hover {
    background-color: rgba(190, 187, 187, 0.5);
    border-radius: 100%;
}

.text-right {
    float: right;
}

.icone-whatsapp {
    color: #495057;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    padding-top: 15px;
    cursor: no-drop;
}

.cardWhatsapp .icone {
    text-decoration: none;
}

.form-inline {
    margin-left: 10px;
}

.menuDev {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.quadradoMenuDev {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: 0.5s;
}

.quadradoMenuDev i {
    font-size: 2em;
}

.quadradoMenuDev:hover {
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(130deg, rgb(181, 146, 216) 0%, rgb(116, 82, 129) 100%);
    background: -webkit-linear-gradient(130deg, rgb(181, 146, 216) 0%, rgb(116, 82, 129) 100%);
    background: linear-gradient(130deg, rgb(181, 146, 216) 0%, rgb(116, 82, 129) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cca5f3", endColorstr="#8a629a", GradientType=1);
    background-color: #8a629a;
    transition: 0.5s;
}

.bg-roxo {
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(130deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: -webkit-linear-gradient(130deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: linear-gradient(130deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cca5f3", endColorstr="#8a629a", GradientType=1);
    background-color: #8a629a;
}

.card-loja img {
    width: 100px;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 20px !important;
}

.shepherd-element {
    width: 50%;
}

/* Tela de Mobile */

@media (max-width:767px) {

    .remove-mobile,
    [remove-mobile] {
        display: none !important;
    }

    .shepherd-element {
        width: 90%;
    }

    .LayoutModeloNavegacao .conteudoPagina {
        padding-top: 57px;
    }

    .card-loja img {
        height: 60px;
        border-radius: 10px !important;
    }
}

/* Tela de Mobile Teclado Ativo */

@media (max-width:767px) and (max-height:450px) {

    .remove-mobile-teclado,
    [remove-mobile-teclado] {
        display: none !important;
    }
}

/* Tela de PC */

@media (min-width:768px) {

    .remove-pc,
    [remove-pc] {
        display: none !important;
    }

    .videoYoutubeCartaoUser {
        margin-left: auto;
        margin-right: auto;
    }

    .LayoutModeloNavegacao .conteudoPagina {
        padding-top: 76px;
    }

    .icon-trash {
        position: absolute;
        right: 0px;
    }

    .icon-eye {
        position: absolute;
        right: 0px;
    }
}

.display-none {
    display: none;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: #ccc;
}

::-webkit-scrollbar-thumb {
    background: rgb(204, 165, 243);
    background: -moz-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: -webkit-linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
    background: linear-gradient(170deg, rgba(204, 165, 243, 1) 0%, rgba(138, 98, 154, 1) 100%);
}