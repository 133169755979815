.tema-PADRAO .btn-azul{
    background-color: #0192ec;
    color: #fff;
}

.tema-PADRAO  .btn-azul:hover{
    color: #0192ec;
    background-color: #fff;
}

.tema-PADRAO .btn-padrao{
    background-color:  #9419E6;
    color: #fff;
}

.tema-PADRAO .btn-padrao:hover{
    color:  #9419E6;
    background-color: #fff;
}

.tema-PADRAO .btn-amarelo{
    background-color: #ffad1f;
    color: #fff;
}

.tema-PADRAO .btn-amarelo:hover{
    color: #ffad1f;
    background-color: #fff;
}

.tema-PADRAO .btn-verde{
    background-color: #00b953;
    color: #fff;
}

.tema-PADRAO .btn-verde:hover{
    color: #00b953;
    background-color: #fff;
}

.tema-PADRAO .btn-vermelho{
    background-color: #f12d2d;
    color: #fff;
}

.tema-PADRAO .btn-vermelho:hover{
    color: #f12d2d;
    background-color: #fff;
}

.tema-PADRAO .btn-rosa{
    background-color: #f12dd7;
    color: #fff;
}

.tema-PADRAO .btn-rosa:hover{
    color: #f12dd7;
    background-color: #fff;
}

.tema-PADRAO .btn-preto-branco{
    background-color: #000;
    color: #fff;
}

.tema-PADRAO .btn-preto-branco:hover{
    color: #fff;
    background-color: #000;
}

.tema-PADRAO .btn-preto{
    background-color: #000;
    color: #fff;
}

.tema-PADRAO .btn-preto:hover{
    color: #000;
    background-color: #fff;
}

.tema-PADRAO .btn-branco{
    background-color: #eee;
    color: #000;
}

.tema-PADRAO .btn-branco:hover{
    color: #fff;
    background-color: #000;
}

.tema-PADRAO.background-padrao{
    background-color: #ecd2fa;
}

.tema-PADRAO.background-verde{
    background-color: #9fe69f;

}

.tema-PADRAO.background-vermelho{
    background-color: #f08080;
}

.tema-PADRAO.background-amarelo{
    background-color: #ecec8a;
}

.tema-PADRAO.background-azul{
    background-color: #6eabd1;
}

.tema-PADRAO.background-rosa{
    background-color: #ec8ad3;
}

.tema-PADRAO.background-preto{
    background-color: #4e4e4e;
    color: #fff;
}

.tema-PADRAO.background-branco{
    background-color: #fff;
}

.tema-PADRAO.background-preto-branco{
    background-color: #fff;
}

.tema-PADRAO .pix-cor-padrao .input-group-text,
.tema-PADRAO .pix-cor-padrao .form-control {
    color: #fff;
    background-color: #9419E6;
}

.tema-PADRAO .pix-cor-azul .input-group-text,
.tema-PADRAO .pix-cor-azul .form-control {
    color: #fff;
    background-color: #0192ec;
}

.tema-PADRAO .pix-cor-verde .input-group-text,
.tema-PADRAO .pix-cor-verde .form-control {
    color: #fff;
    background-color: #00b953;
}

.tema-PADRAO .pix-cor-amarelo .input-group-text,
.tema-PADRAO .pix-cor-amarelo .form-control {
    color: #fff;
    background-color: #ffad1f;
}

.tema-PADRAO .pix-cor-vermelho .input-group-text,
.tema-PADRAO .pix-cor-vermelho .form-control {
    color: #fff;
    background-color: #f12d2d;
}

.tema-PADRAO .pix-cor-rosa .input-group-text,
.tema-PADRAO .pix-cor-rosa .form-control {
    color: #fff;
    background-color: #f12dd7;
}

.tema-PADRAO .pix-cor-preto-branco .input-group-text,
.tema-PADRAO .pix-cor-preto-branco .form-control {
    color: #fff;
    background-color: #000;
}

.tema-PADRAO .pix-cor-preto .input-group-text,
.tema-PADRAO .pix-cor-preto .form-control {
    color: #fff;
    background-color: #000;
}

.tema-PADRAO .pix-cor-branco .input-group-text,
.tema-PADRAO .pix-cor-branco .form-control {
    color: #000;
    background-color: #eee;
}
.paginaCartao .tema-PADRAO .pix-cor-branco .input-esquerdo img {
    filter: brightness(0) invert(0);
}