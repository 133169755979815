.tema-BARMENUS .btn-azul {
    color: #fff;
    background-color: #0192ec;
}

.tema-BARMENUS .btn-azul:hover {
    color: #0192ec;
    border-color: #0192ec;
}

.tema-BARMENUS .btn-padrao {
    color: #fff;
    background-color: #9419E6;
}

.tema-BARMENUS .btn-padrao:hover {
    color: #9419E6;
    border-color: #9419E6;
}

.tema-BARMENUS .btn-amarelo {
    color: #fff;
    background-color: #ffad1f;
}

.tema-BARMENUS .btn-amarelo:hover {
    color: #ffad1f;
    border-color: #ffad1f;
}

.tema-BARMENUS .btn-verde {
    color: #fff;
    background-color: #00b953;
}

.tema-BARMENUS .btn-verde:hover {
    color: #00b953;
    border-color: #00b953;
}

.tema-BARMENUS .btn-vermelho {
    color: #fff;
    background-color: #f12d2d;
}

.tema-BARMENUS .btn-vermelho:hover {
    color: #f12d2d;
    border-color: #f12d2d;
}

.tema-BARMENUS .btn-rosa {
    color: #fff;
    background-color: #f12dd7;
}

.tema-BARMENUS .btn-rosa:hover {
    color: #f12dd7;
    border-color: #f12dd7;
}

.tema-BARMENUS .btn-preto-branco {
    color: #fff;
    background-color: #000;
}

.tema-BARMENUS .btn-preto-branco:hover {
    color: #000;
    border-color: #000;
}

.tema-BARMENUS .btn-preto {
    color: #fff;
    background-color: #000;
}

.tema-BARMENUS .btn-preto:hover {
    color: #000;
    border-color: #000;
}

.tema-BARMENUS .btn-branco {
    color: #000;
    background-color: #eee;
}

.tema-BARMENUS .btn-branco:hover {
    color: #000;
    border-color: #eee;
}

.tema-BARMENUS.background-padrao {
    background-color: #ecd2fa;
}

.tema-BARMENUS.background-verde {
    background-color: #9fe69f;

}

.tema-BARMENUS.background-vermelho {
    background-color: #f08080;
}

.tema-BARMENUS.background-amarelo {
    background-color: #ecec8a;
}

.tema-BARMENUS.background-azul {
    background-color: #6eabd1;
}

.tema-BARMENUS.background-rosa {
    background-color: #ec8ad3;
}

.tema-BARMENUS.background-preto {
    background-color: #4e4e4e;
    color: #fff;
}

.tema-BARMENUS.background-branco {
    background-color: #fff;
}

.tema-BARMENUS.background-preto-branco {
    background-color: #fff;
}

.tema-BARMENUS .pix-cor-padrao .input-group-text,
.tema-BARMENUS .pix-cor-padrao .form-control {
    color: #fff;
    background-color: #9419E6;
}

.tema-BARMENUS .pix-cor-azul .input-group-text,
.tema-BARMENUS .pix-cor-azul .form-control {
    color: #fff;
    background-color: #0192ec;
}

.tema-BARMENUS .pix-cor-verde .input-group-text,
.tema-BARMENUS .pix-cor-verde .form-control {
    color: #fff;
    background-color: #00b953;
}

.tema-BARMENUS .pix-cor-amarelo .input-group-text,
.tema-BARMENUS .pix-cor-amarelo .form-control {
    color: #fff;
    background-color: #ffad1f;
}

.tema-BARMENUS .pix-cor-vermelho .input-group-text,
.tema-BARMENUS .pix-cor-vermelho .form-control {
    color: #fff;
    background-color: #f12d2d;
}

.tema-BARMENUS .pix-cor-rosa .input-group-text,
.tema-BARMENUS .pix-cor-rosa .form-control {
    color: #fff;
    background-color: #f12dd7;
}

.tema-BARMENUS .pix-cor-preto-branco .input-group-text,
.tema-BARMENUS .pix-cor-preto-branco .form-control {
    color: #fff;
    background-color: #000;
}

.tema-BARMENUS .pix-cor-preto .input-group-text,
.tema-BARMENUS .pix-cor-preto .form-control {
    color: #fff;
    background-color: #000;
}

.tema-BARMENUS .pix-cor-branco .input-group-text,
.tema-BARMENUS .pix-cor-branco .form-control {
    color: #000;
    background-color: #eee;
}
.paginaCartao .tema-BARMENUS .pix-cor-branco .input-esquerdo img {
    filter: brightness(0) invert(0);
}