.divTopoTema.tema-PADRAO {
    min-height: 100vh;
}

.divTopoTema.tema-PADRAO .container {
    max-width: 700px;
    margin: auto;
}

.paginaCartao .tema-PADRAO .conteudo{
    position: relative;
    padding-top: 15px;
    z-index: 1;
}
.paginaCartao .tema-PADRAO .botoes{
    margin-top: 30px;
}
.paginaCartao .tema-PADRAO .botoes .botao{
    display: inline-block;
    border: none;
    border-radius: 100%;
    /* color: #fff; */
    padding: 15px;
    margin-right: 10px;
    margin-top: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.164), inset 0 -1px 0 rgba(0, 0, 0, 0.274) ;
    transition: 0.25s;
}


.paginaCartao .tema-PADRAO .nome-perfil{
    font-weight: 700;
}

.fal {
    font-weight: normal;
}

.paginaCartao .tema-PADRAO .botoes .botao i{
    width: 30px;
}

.paginaCartao .tema-PADRAO .botoes .botao:hover{
    transition: 0.25s;
}

.paginaCartao .tema-PADRAO .botoes .fa-youtube{
    margin-top: 4px;
    font-size: 1.8em;
}
.paginaCartao .tema-PADRAO  .rodape{
    padding: 10%;
}

.paginaCartao .tema-PADRAO .appPix .input-esquerdo img {
    filter: brightness(0) invert(1);
}

.paginaCartao .tema-PADRAO .appPix {
    margin-bottom: 1rem;
}

.paginaCartao .tema-PADRAO .appPix .input-esquerdo {
    border-radius: 1rem 0rem 0rem 1rem;
    border-color: transparent;
}

.paginaCartao .tema-PADRAO .appPix .input-direito {
    border-radius: 0rem 1rem 1rem 0rem;
    border-color: transparent;
    cursor: pointer;
}

.paginaCartao .tema-PADRAO .appPix .input-group .form-control{
    border-color: transparent;
    border: 1px;
}

/* Tela de Mobile */
@media (max-width:767px) {
    .remove-mobile, [remove-mobile] {
        display: none!important;
    }
    
}

/* Tela de Mobile Teclado Ativo */
@media (max-width:767px) and (max-height:450px) {
    .remove-mobile-teclado, [remove-mobile-teclado] {
    display: none!important;
    }
}

/* Tela de PC */
@media (min-width:768px) {
    .remove-pc, [remove-pc] {
        display: none!important;
    }
}